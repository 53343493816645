<template>
    <div>
        <div class="card-toolbar mb-5">
            <router-link v-if="$can('notification_config.create')"  to="/settings/notifications-config/create"  class="btn btn-primary font-weight-bolder"><v-icon>mdi-plus</v-icon> {{ $t('notifications_config.new_notification_config') }}</router-link>
            <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5" >
                <span><i class="la la-filter"></i> <span>{{$t('advanced_filter')}}</span></span>
            </button>
        </div>

        <div class="card card-custom mb-5" v-if="showAdvancedSearch">
            <div class="card-body">
                <div class="m-form m-form--fit m--margin-bottom-20">
                    <div class="row">
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="document_type">
                                {{$t('notifications_config.document_type')}}
                            </label>
                            <multiselect v-model="doc_type" id="document_type"
                                :placeholder="$t('notifications_config.document_type')"
                                label="title"
                                track-by="id"
                                :options="document_type_list"
                                :multiple="false"
                                :taggable="true"
                                :show-labels="false"
                                :show-no-options="false"
                                :show-no-results="false">
                            </multiselect>
                        </div>

                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label>{{$t('status')}}</label>
                            <select name="" id="status" v-model="filters.status" class="custom-select">
                                <option v-for="row in statusList" :value="row.id" :key="row.id">{{ row.title }}</option>
                            </select>
                        </div>
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label>{{$t('notifications_config.action')}}</label>
                            <select name="action" id="action" v-model="filters.action" class="custom-select">
                                <option v-for="row in action_list" :value="row.id" :key="row.id">{{row.title}}</option>
                            </select>
                        </div>
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label>{{$t('notifications_config.added_by')}}</label>
                            <multiselect v-model="user"
                                         :placeholder="$t('added_by')"
                                         label="name"
                                         track-by="id"
                                         :options="users"
                                         :multiple="false"
                                         :taggable="false"
                                         :show-labels="false"
                                         :show-no-options="false"
                                         :show-no-results="false"
                                         @search-change="getUsers($event)">
                            </multiselect>
                        </div>
                        <div class="form-group d-inline-flex col-md-6 mt-10">
                            <b-button class="mt-auto mr-2" variant="primary" @click="doFilter"><i class="fas fa-search"></i> {{$t('search')}}</b-button>
                            <b-button class="mt-auto mr-2" variant="danger" @click="resetFilter"><i class="fas fa-trash-restore"></i> {{$t('reset_search')}}</b-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--begin::User-->
        <div class="card card-custom">
            <div class="card-body">

                <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">

<!--                    <template slot="columns_doc" slot-scope="props">-->
<!--                        <span class="badge badge-light" v-for="(name, index) in props.row.columns_doc" :key="index">{{name}}</span>-->
<!--                    </template>-->
                    <template slot="is_active" slot-scope="props">
                        <b-form-checkbox v-if="$can('notification_config.update')" size="lg" @change="changeStatus(props.row.id, props.row.is_active)" v-model="props.row.is_active" :name="'check-button'+props.row.id" switch :key="props.row.id"></b-form-checkbox>
                    </template>
                    <template slot="actions" slot-scope="props">

                        <v-icon small class="mr-2 text-info" v-b-tooltip.hover :title="$t('edit')" v-if="$can('notification_config.update')" @click="editItem(props.row)">mdi-pencil</v-icon>
                        <v-icon small class="mr-2 text-danger" v-b-tooltip.hover :title="$t('delete')" v-if="$can('notification_config.delete')" @click="deleteItem(props.row)">mdi-delete</v-icon>

                    </template>
                </v-server-table>
                <!--end: Datatable-->
            </div>
        </div>
        <!--end::User-->
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";

    export default {
        name: "index-notification-config",
        data() {
            return {
                mainRoute: 'settings/notifications_config',
                subMainRoute: 'settings/notification_config',
                mainRouteDependency: 'base/dependency',

                showAdvancedSearch: false,
                dataList: [],
                filters: {
                    doc_type: null,
                    user_id : null,
                    status: null,
                    action: null,
                },

                users: [],
                statusList :[],
                action_list :[],

                user: null,
                doc_type : null,
                document_type_list: [],

                columns: ['doc_type_name', 'action_name', 'added_by', 'created_at', 'is_active', 'actions'],
            }
        },
        watch: {
            user: function (val) {
                if (val) {
                    this.filters.user_id = val.id;
                } else {
                    this.filters.user_id = null;
                }
            },
            doc_type : function (val) {
                if (val) {
                    this.filters.doc_type = val.id;
                    this.getStatusList(val.id);
                } else {
                    this.filters.doc_type = null;
                }
            },
        },
        computed: {
            options: function () {
                let that = this;
                return {
                    texts: {
                        loadingError: that.$t('Something_went_wrong'),
                        filter: "",
                        limit: that.$t('records'),
                        filterBy: that.$t('Filter') + ' {column}',
                        count: ' ',
                        filterPlaceholder: that.$t('Search_query'),
                        loading: that.$t('Loading') + "...",
                    },
                    headings: {
                        doc_type_name: that.$t('notifications_config.document_type'),
                        action_name: that.$t('notifications_config.action'),
                        added_by: that.$t('notifications_config.added_by'),
                        created_at: that.$t('notifications_config.created_at'),
                        // columns_name: that.$t('notifications_config.columns'),
                        is_active: that.$t('notifications_config.is_active'),
                        actions: that.$t('actions'),

                    },
                    sortable: ['name'],
                    filterByColumn: false,
                    filterable: false,
                    customFilters: [{
                        name: 'alphabet',

                    }],
                    orderBy: {'column': 'id'},

                    alwaysShowPerPageSelect: true,
                    perPage: 10,
                    pagination: {chunk: 5, dropdown: false},
                    skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
                    sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
                    perPageValues: [5, 10, 15, 25],

                    requestFunction(data) {
                        let _params = {
                            ascending: 0,
                            byColumn: data.byColumn,
                            limit: data.limit,
                            orderBy: data.orderBy,
                            page: data.page,
                            filter: data.query,
                            ...that.filters,
                        }
                        return ApiService.query(that.mainRoute, {..._params});

                    },
                    responseAdapter(resp) {
                        that.dataList = resp.data.data.data;
                        return {
                            data: resp.data.data.data,
                            count: resp.data.data.total,
                        }
                    },

                }

            },
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.notifications_config")}]);
            this.getDocumentTypeList();
            this.getUsers();
            this.getActionsList();
        },
        methods: {
            getFetch() {
                this.$refs.table.refresh();
            },
            openAdvancedSearch() {
                this.showAdvancedSearch = !this.showAdvancedSearch;
            },
            doFilter() {
                this.$refs.table.refresh();
            },
            resetFilter() {
                this.filters.doc_type = null;
                this.filters.status = null;
                this.filters.action = null;
                this.user= null;
                this.doc_type = null;
                this.$refs.table.refresh();
            },
            getUsers(filter) {
                if(filter && filter.length >= 3)
                    ApiService.get(this.mainRouteDependency + "/users",{params: {filter: filter}}).then((response) => {
                        this.users = response.data.data;
                    });
            },
            actionDelete(item) {
                ApiService.delete(this.mainRoute + "/" + item.id).then((response) => {
                    this.getFetch();
                    this.$successAlert(response.data.message)
                }).catch((error) => {
                    this.$errorAlert(error);
                })
            },
            getStatusList(id) {
                ApiService.get(this.mainRouteDependency + "/status_by_doc_type/" + id).then((response) => {
                    this.statusList = response.data.data;
                });
            },
            editItem(item) {
                this.$router.push({name: 'notifications-config.edit', params: {id: item.id}});
            },
            deleteItem(item) {
                this.$confirmAlert('', this.actionDelete, item);
            },

            getDocumentTypeList() {
                ApiService.get(this.mainRouteDependency + "/doc_type_list").then((response) => {
                    this.document_type_list = response.data.data;
                });
            },
            getActionsList() {
                ApiService.get(this.mainRouteDependency + "/actions_list").then((response) => {
                    this.action_list = response.data.data;
                });
            },
            changeStatus(id, status) {
                ApiService.patch(this.subMainRoute + '/change-status/' + id, {
                    is_active: (status ? 1 : 0),
                }).then(response => {
                    this.$refs.table.refresh();
                    this.$successAlert(response.data.message);
                }).catch(error => {
                    this.$errorAlert(error);
                });
            },

        },
    };
</script>
